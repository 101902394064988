<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name:'Globals',
    data()
    {
        // state key contains store data
        // constant key will contain constants which will be used in all components
        return{globalData:{
            "orgRole": "",
            "userRole": "",
            "email": this.email,
            "emailVerified": this.emailVerified,
            "organizationName":this.organizationName,
            // "token":this.token,
            "phoneNumber": this.phoneNumber,
            "phoneNumberVerified": this.phoneNumberVerified,
            "organizationId": this.organizationId,
            "category": this.category,
            "name":this.name
        },
            DOWN :"Down",
            RUNNING : "Running",
            NA  : "NA",
            PARTIALLY : "Partially Running",
            NO_ORG : "Organization Not Available!"
          


        // organizationData:{
        //      "name": "",
        //     "email": "",
        //     "contact": "",
        //     "gstNo": "",
        //     "panNo": "",
        //     "cinNo": "",
        //     "city": "",
        //     "state": "",
        //     "zipCode": "",
        //     "countryOfBusiness": "",
        //     "address": "",
        //     "teleCountryCode": "",
        //     "mobileNumber": "",
        //     "toDate": "",
        //     "fromDate": "",
        //     "createdDate": "",
        //     "modifiedDate": "",
        //     "logo": ""
        // }
        }
    },
    computed:{
        ...mapState(["orgRole","userRole","email","emailVerified","organizationName","name","phoneNumber","phoneNumberVerified","organizationId","organizationName","category"])
    },
    methods:{
            getOrgRole()
            {
                // let role;
                // for (const key in this.$store.state.orgRole)
                // {
                //     if(this.$store.state.orgRole[key]==true)
                //     {
                //         role = key
                //         break
                //     }
                // }
                return this.$store.state.orgRole
            },
            getUserRole()
            {
                // let role;
                // for (const key in this.$store.state.userRole)
                // {
                //     if(this.$store.state.userRole[key]==true)
                //     {
                //         role = key
                //         break
                //     }
                // }
                return this.$store.state.userRole
            },
            getDateInUtc(incomingDate)
            {
                incomingDate = incomingDate.replace("T"," ")
                incomingDate =  new Date(incomingDate);
                incomingDate.setMinutes(incomingDate.getMinutes() - incomingDate.getTimezoneOffset());
                incomingDate= incomingDate.toDateString()+" "+ incomingDate.toLocaleTimeString()+" "+
                new Date(incomingDate).toLocaleDateString('en-IN',
                 {day:'2-digit',timeZoneName: 'short' }).substring(4);
                return incomingDate
                // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;- get time from user browser
                // incomingDate=  incomingDate.toLocaleString()+" "+timezone ;
                // return incomingDate
            },




        // fetchStateData()
        // {
        //     for (const key in this.$store.state.orgRole)
        //     {
        //         if(this.$store.state.orgRole[key]==true)
        //         {
        //             this.orgRole = key
        //         }
        //     }
        //     // this.orgRole = this.$store.state.orgRole
        //     for (const key in this.$store.state.userRole)
        //     {
        //         if(this.$store.state.userRole[key]==true)
        //         {
        //             this.userRole = key
        //         }
        //     }
        //     // this.userRole = this.$store.state.userRole
        //     this.email = this.$store.state.email
        //     this.emailVerified = this.$store.state.emailVerified
        //     this.token = this.$store.state.token
        //     this.phoneNumber = this.$store.state.phoneNumber
        //     this.phoneNumberVerified = this.$store.state.phoneNumberVerified
        //     this.organizationId = this.$store.state.organizationId
        //     // return [this.orgRole,this.userRole,this.email,this.emailVerified,this.token,this.phoneNumber,this.phoneNumberVerified,this.organizationId]
        //     return {'orgRole': this.orgRole,
        //     'userRole': this.userRole,
        //     'email': this.email,
        //     'emailVerified': this.emailVerified,
        //     'token': this.token,
        //     'phoneNumber': this.phoneNumber,
        //     'phoneNumberVerified': this.phoneNumberVerified,
        //     'organizationId': this.organizationId}
        // }
    }
}
</script>

<style></style>

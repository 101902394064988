<template>
  <div v-if="render" class="card card-flush">
    <div class="card-header mt-3">
      <!--begin::Card title-->
      <div class="card-title">
        <h1>API Keys</h1>
      </div>
    </div>
    <div class="card-body pt-0">
      <!--begin::Table-->
      <div
        id="kt_permissions_table_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="table-responsive">
          <table
            class="table align-middle table-row-dashed fs-6 gy-5 mb-0"
            id="kt_permissions_table"
          >
            <thead>
              <tr
                class="
                  text-start text-gray-700
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
              >
                <th class="min-w-100px">Key ID</th>
                <th class="min-w-50px">Modified At</th>
                <th class="min-w-50px">Status</th>
                <th class="text-end min-w-100px">Regenerate API Secret</th>
              </tr>
            </thead>
            <tbody class="fw-bold text-gray-600">
              <tr>
                <td>
                  {{ apiKeyData.apiKey }}

                  <button
                    class="
                      btn
                      btn-active-color-primary
                      btn-icon
                      btn-sm
                      btn-outline-light
                    "
                    v-on:click="copy(apiKeyData.apiKey)"
                  >
                    <span class="svg-icon" v-if="apiKeyData.apiKey">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#F6B150"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <rect
                          x="9"
                          y="9"
                          width="13"
                          height="13"
                          rx="2"
                          ry="2"
                        ></rect>

                        <path
                          d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </td>

                <td>
                  <span v-if="apiKeyData.apiKey"> {{ apiKeyData.modifiedDate }} </span>
                </td>

                <td>
                  <span v-if="apiKeyData.apiKey"> {{ apiKeyData.status }} </span>
                </td>

                <td class="text-end">
                  <el-popconfirm
                    title="Are you sure to Regenerate API Secret?"
                    confirm-button-text="Yes"
                    cancel-button-text="No"
                    @confirm="create()"
                    @cancel="cancelEvent"
                  >
                    <template #reference>
                      <el-button
                        class="
                          text-start textYellow
                          fw-bolder
                          fs-7
                          text-uppercase
                          gs-0
                        "
                      >
                        <span v-if="apiKeyData.apiKey"> Regenerate </span> 
                        <span v-else> Generate </span>
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#F6B150"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38"
                            />
                          </svg>
                        </span>
                      </el-button>
                    </template>
                  </el-popconfirm>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <button
    :disabled="false"
    class="btn btn-icon btn-active-light-primary w-30px h-30px me-3 fw-bolder"
    data-bs-toggle="modal"
    data-bs-target="#kt_modal_show_api_key"
    id="modal_open_button"
    style="display: none;"
  >
    <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
    <span class="svg-icon svg-icon-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
          fill="black"
        />
      </svg>
      <path
        opacity="0.6"
        d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
        fill="black"
      />
    </span>

    <!--end::Svg Icon-->
  </button>
  <ShowApiKeyModal :item="item" ref="showApiKeyForm"></ShowApiKeyModal>
</template>

<script>
import { saveAs } from "file-saver";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { ElLoading } from "element-plus";
import Globals from "@/views/common/Globals.vue";
import router from "@/router";
import ShowApiKeyModal from "@/components/modals/forms/ShowApiKeyModal.vue";
export default defineComponent({
    name: "ApiKey",
    mixins: [Globals],
    data() {
        return {
            // apiKeys: "",
            APIProductionKey: "",
            APIProductionSecretKey: "",
            APIDevelopmentKey: "",
            APIDevelopmentSecretKey: "",
            role: this.getOrgRole(),
            apiSecret: "",
            render: false,
            apiKeyData: {},
            requestBody: {
                application: "",
                organizationType: "",
                organizationId: "",
                data: {
                    organizationId: "",
                },
            },
        };
    },
    methods: {
        displayData(data, method) {
          this.apiKeyData = data;
          //debugger;
          if (this.apiKeyData.length != 0) {
              this.render = true;
          }
          this.apiKeyData.status = "Inactive";
          if (this.apiKeyData.toDate && this.apiKeyData.toDate == "0000-00-00 00:00:00") {
            this.apiKeyData.status = 'Active';
          }
          else {
            if (this.apiKeyData.toDate == "" || !this.apiKeyData.toDate) {
              this.apiKeyData.status = 'Active';
            }
            if (this.apiKeyData.toDate && this.getDateInUtc(this.apiKeyData.toDate) > new Date()) {
              this.apiKeyData.status = 'Active';
            }
          }
          if (this.apiKeyData.createdDate){
            this.apiKeyData.createdDate = this.getDateInUtc(this.apiKeyData.createdDate);
          }
          else{
            this.apiKeyData.createdDate = "";
          }
          if (this.apiKeyData.modifiedDate){
            this.apiKeyData.modifiedDate = this.getDateInUtc(this.apiKeyData.modifiedDate);
          }
          else{
            this.apiKeyData.modifiedDate = "";
          }
          this.$refs['showApiKeyForm'].callUpdate(this.apiKeyData.apiKey, this.apiKeyData.apiSecret);
          if (method == 'put') {
            document.getElementById('modal_open_button').click();
          }
        },
        fetchKeysFromDb() {
            this.render = false;
            const loading = ElLoading.service({
                lock: true,
                text: "Loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            ApiService.get("/apikey?organizationId=" + this.globalData.organizationId)
                .then(({ data }) => {
                if (data.errors.length != 0) {
                    //debugger;
                    console.log("API Keys is not generated with this " + data.errors[0].args);
                }
                else {
                    loading.close();
                    this.displayData(data.data, 'get');
                }
            })
                .catch(({ error }) => {
                loading.close();
            });
        },
        copy(text) {
            let copytext = {
                "Api Key": this.apiKeyData.apiKey,
                "Api Secret": this.apiKeyData.apiSecret,
            };
            navigator.clipboard
                .writeText(text.replace(/["]/g, "\\"))
                .then(() => {
                ElNotification({
                    title: "Copy",
                    message: "Copied to Clipboard",
                    type: "success",
                    duration: 2000,
                    position: "top-right",
                });
            })
                .catch(() => {
                ElNotification({
                    title: "Error",
                    message: "Copied to Clipboard failed",
                    type: "warning",
                    duration: 2000,
                    position: "top-right",
                });
            });
        },
        checkRole() {
            if (this.role == "consumer")
                this.role = "C";
            else if (this.role == "manufacturer")
                this.role = "M";
            else if (this.role == "api_provider")
                this.role = "P";
            else if (this.role == "1SB")
                this.role = "P";
            return this.role;
        },
        create() {
            this.requestBody.organizationType = this.checkRole();
            this.requestBody.organizationId = this.globalData.organizationId;
            this.requestBody.data.organizationId = this.globalData.organizationId;
            this.render = false;
            const loading = ElLoading.service({
                lock: true,
                text: "Loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            ApiService.put("/apikey", this.requestBody)
                .then(({ data }) => {
                loading.close();
                this.displayData(data.data, "put");
            })
                .catch((error) => {
                loading.close();
            });
        },
        cancelEvent() {
            console.log("cancel!");
        },
        refreshPage() {
          // Reload the page using window.location.reload()
          window.location.reload();
        },
    },
    mounted() {
      console.log("apikey page is getting mounted.");
      setCurrentPageBreadcrumbs("API Keys", ["API"]);
      // this.stateData = this.fetchStateData()
      this.fetchKeysFromDb();
    },
    components: { ShowApiKeyModal }
});
</script>

<style>
@import '../../../assets/style.css';

.Header {
  margin-top: 10px;
  margin-left: 30px;
}
</style>
